import * as AuthenticationActions from "redux/authentication/authenticationActions";
import * as doctorsListActions from "redux/doctor/doctorsList/doctorsListActions";
import * as monthSchedulesActions from "redux/schedules/monthSchedules/monthSchedulesActions";
import * as weekSchedulesActions from "redux/schedules/weekSchedules/weekSchedulesActions";
import * as appointmentsListActions from "redux/appointments/appointmentsList/appointmentsListActions";
import * as doctorAppointmentsActions from "redux/appointments/doctorAppointments/doctorAppointmentsActions";
import * as appointmentDetailsActions from "redux/appointments/appointmentDetails/appointmentDetailsActions";
import * as deleteDoctorActions from "redux/doctor/deleteDoctor/deleteDoctorActions";
import * as createDoctorActions from "redux/doctor/createDoctor/createDoctorActions";
import * as postDoctorSchedulesActions from "redux/schedules/postDoctorSchedules/postDoctorSchedulesActions";
import * as deleteScheduleActions from "redux/schedules/deleteSchedule/deleteScheduleActions";
import * as createBulkSchedulesActions from "redux/schedules/createBulkSchedules/createBulkSchedulesActions";
import * as monthAppointmentsActions from "redux/appointments/monthAppointments/monthAppointmentsActions";
import * as editDoctorActions from "redux/doctor/editDoctor/editDoctorActions";
import * as searchMedicineActions from "redux/medicine/search/searchActions";
import * as createMedicineActions from "redux/medicine/create/createActions";
import * as getMedicineListActions from "redux/medicine/list/listActions";
import * as getMedicineListByIDActions from "redux/medicine/listByID/listByIDActions";
import * as updateMedicineByIDActions from "redux/medicine/updateByID/updateByIDActions";
import * as deleteMedicineByIDActions from "redux/medicine/deleteByID/deleteByIDActions";
import * as subSearchMedicineActions from "redux/medicine/subSearch/subSearchActions";
import * as createMedicineByIDActions from "redux/medicine/createByID/createByIDActions";
import * as medicalRecordActions from "redux/medicalRecords/medicalRecord/medicalRecordActions";
import * as createMedicalRecordActions from "redux/medicalRecords/create/createActions";
import * as updateMedicalRecordActions from "redux/medicalRecords/update/updateActions";
import * as clinicalDepartmentListActions from "redux/medicalRecords/clinicalDepartmentList/clinicalDepartmentListActions";
import * as versionHistoryActions from "redux/medicalRecords/versionHistory/versionHistoryActions";
import * as updateStatusConsultationAppointmentActions from "redux/appointments/updateStatusConsultation/updateStatusConsultationActions";
import * as cancelStatusAppointmentActions from "redux/appointments/cancelStatus/cancelStatusActions";
import * as updateStatusAppointmentActions from "redux/appointments/updateStatus/updateStatusActions";
import * as medicalPlanActions from "redux/medicalPlans/medicalPlan/medicalPlanActions";
import * as notifyMedicalPlanActions from "redux/medicalPlans/notifyMedicalPlan/notifyMedicalPlanActions";
import * as createMedicalPlanActions from "redux/medicalPlans/createMedicalPlan/createMedicalPlanActions";
import * as editMedicalPlanActions from "redux/medicalPlans/editMedicalPlan/editMedicalPlanActions";
import * as cancelAppointmentActions from "redux/appointments/cancelAppointment/cancelAppointmentActions";
import * as hospitalListActions from "redux/doctor/hospitalList/hospitalListActions";

export const ActionCreators = Object.assign({}, { ...AuthenticationActions });

export const GetDoctorsListActionCreators = Object.assign(
  {},
  { ...doctorsListActions }
);

export const GetMonthSchedulesActionCreators = Object.assign(
  {},
  { ...monthSchedulesActions }
);

export const GetWeekSchedulesActionCreators = Object.assign(
  {},
  { ...weekSchedulesActions }
);

export const GetAppointmentsListActionCreators = Object.assign(
  {},
  { ...appointmentsListActions }
);

export const GetDoctorAppointmentsActionCreators = Object.assign(
  {},
  { ...doctorAppointmentsActions }
);

export const GetAppointmentDetailsActionCreators = Object.assign(
  {},
  { ...appointmentDetailsActions }
);

export const DeleteDoctorActionCreators = Object.assign(
  {},
  { ...deleteDoctorActions }
);

export const CreateDoctorActionCreators = Object.assign(
  {},
  { ...createDoctorActions }
);

export const PostDoctorSchedulesActionCreators = Object.assign(
  {},
  { ...postDoctorSchedulesActions }
);

export const DeleteScheduleActionCreators = Object.assign(
  {},
  { ...deleteScheduleActions }
);

export const CreateBulkSchedulesActionCreators = Object.assign(
  {},
  { ...createBulkSchedulesActions }
);

export const GetMonthAppointmentsActionCreators = Object.assign(
  {},
  { ...monthAppointmentsActions }
);

export const EditDoctorActionCreators = Object.assign(
  {},
  { ...editDoctorActions }
);

export const SearchMedicineActionCreators = Object.assign(
  {},
  { ...searchMedicineActions }
);

export const CreateMedicineActionCreators = Object.assign(
  {},
  { ...createMedicineActions }
);

export const GetMedicineListActionCreators = Object.assign(
  {},
  { ...getMedicineListActions }
);

export const GetMedicineListByIDActionCreators = Object.assign(
  {},
  { ...getMedicineListByIDActions }
);

export const UpdateMedicineByIDActionCreators = Object.assign(
  {},
  { ...updateMedicineByIDActions }
);

export const DeleteMedicineByIDActionCreators = Object.assign(
  {},
  { ...deleteMedicineByIDActions }
);

export const SubSearchMedicineActionCreators = Object.assign(
  {},
  { ...subSearchMedicineActions }
);

export const CreateMedicineByIDActionCreators = Object.assign(
  {},
  { ...createMedicineByIDActions }
);

export const GetMedicalRecordActionCreators = Object.assign(
  {},
  { ...medicalRecordActions }
);

export const CreateMedicalRecordActionCreators = Object.assign(
  {},
  { ...createMedicalRecordActions }
);

export const UpdateMedicalRecordActionCreators = Object.assign(
  {},
  { ...updateMedicalRecordActions }
);

export const ClinicalDepartmentListActionCreators = Object.assign(
  {},
  { ...clinicalDepartmentListActions }
);

export const GetVersionHistoryActionCreators = Object.assign(
  {},
  { ...versionHistoryActions }
);

export const UpdateStatusConsultationAppointmentActionCreators = Object.assign(
  {},
  { ...updateStatusConsultationAppointmentActions }
);

export const CancelStatusAppointmentActionCreators = Object.assign(
  {},
  { ...cancelStatusAppointmentActions }
);

export const UpdateStatusAppointmentActionCreators = Object.assign(
  {},
  { ...updateStatusAppointmentActions }
);

export const GetMedicalPlanActionCreators = Object.assign(
  {},
  { ...medicalPlanActions }
);

export const NotifyMedicalPlanActionCreators = Object.assign(
  {},
  { ...notifyMedicalPlanActions }
);

export const CreateMedicalPlanActionCreators = Object.assign(
  {},
  { ...createMedicalPlanActions }
);

export const EditMedicalPlanActionCreators = Object.assign(
  {},
  { ...editMedicalPlanActions }
);

export const CancelAppointmentActionCreators = Object.assign(
  {},
  { ...cancelAppointmentActions }
);

export const GetHospitalsActionCreators = Object.assign(
  {},
  { ...hospitalListActions }
);
