import {
  Action,
  ActionType,
  IHospitalListResponse,
} from "models/doctor/hospitalList";
import createReducer from "redux/createReducer";

export interface HospitalListReducerType {
  loading: boolean;
  error?: string;
  hospitalListResponse?: IHospitalListResponse;
}

const defaultState: HospitalListReducerType = {
  loading: false,
  error: undefined,
  hospitalListResponse: undefined,
};

export const hospitalListReducer = createReducer<HospitalListReducerType>(
  defaultState,
  {
    [ActionType.HOSPITAL_LIST](state: HospitalListReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.HOSPITAL_LIST_ERROR](
      state: HospitalListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.HOSPITAL_LIST_SUCCESS](
      state: HospitalListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        hospitalListResponse: action.payload,
      };
    },

    [ActionType.HOSPITAL_LIST_RESET](state: HospitalListReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
        hospitalListResponse: undefined,
      };
    },
  }
);
