import { IResponse } from "models";

export enum ActionType {
  HOSPITAL_LIST = "action/HOSPITAL_LIST",
  HOSPITAL_LIST_SUCCESS = "action/HOSPITAL_LIST_SUCCESS",
  HOSPITAL_LIST_ERROR = "action/HOSPITAL_LIST_ERROR",
  HOSPITAL_LIST_RESET = "action/HOSPITAL_LIST_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IHospitalListResponse extends IResponse {
  //   result: {
  //     total: number;
  //     doctors: IDoctor[];
  //   };
  result: ILinkHospital[];
}

export interface ILinkHospital {
  hospital_id: number;
  hospital_name: string;
}
