import { ActionType, IHospitalListResponse } from "models/doctor/hospitalList";

export const getHospitalListAction = () => {
  return {
    type: ActionType.HOSPITAL_LIST,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.HOSPITAL_LIST_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: IHospitalListResponse) => {
  return {
    type: ActionType.HOSPITAL_LIST_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.HOSPITAL_LIST_RESET,
  };
};
