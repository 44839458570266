import { put, takeLatest, fork, call } from "redux-saga/effects";
import { ActionType, IHospitalListResponse } from "models/doctor/hospitalList";
import { httpStatus } from "configs/httpStatus";
import { getHospitalList } from "services/doctor";
import { handleSuccessAction, handleErrorAction } from "./hospitalListActions";

function* getHospitalListSaga() {
  try {
    const response: IHospitalListResponse = yield call(getHospitalList);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetHospitalListWatcher() {
  yield takeLatest(ActionType.HOSPITAL_LIST as any, getHospitalListSaga);
}

export default [fork(onGetHospitalListWatcher)];
