import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, notification, Image } from "antd";
import { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";
import moment from "moment";

import { Input, TextArea, Button } from "components";
import {
  BackIcon,
  DocumentIcon,
  ConfirmFailIcon,
  SendCompleteIcon,
  CloseSidebarIcon,
  CloseIcon,
} from "icons";
import { localStorageHelper } from "utils";
import { httpStatus } from "configs/httpStatus";

import {
  IAppointmentDetails,
  IConsultation,
  IHistoryItem,
} from "models/appointments/appointmentDetails";
import { IMonthAppointment } from "models/appointments/monthAppointmentsList";

import {
  GetAppointmentDetailsActionCreators,
  GetAppointmentsListActionCreators,
  GetMedicalRecordActionCreators,
  GetMedicineListByIDActionCreators,
  DeleteMedicineByIDActionCreators,
  SubSearchMedicineActionCreators,
  CreateMedicalRecordActionCreators,
  UpdateMedicalRecordActionCreators,
  ClinicalDepartmentListActionCreators,
  UpdateMedicineByIDActionCreators,
  UpdateStatusConsultationAppointmentActionCreators,
  CancelStatusAppointmentActionCreators,
  UpdateStatusAppointmentActionCreators,
  CreateMedicineByIDActionCreators,
  GetMedicineListActionCreators,
  GetVersionHistoryActionCreators,
  GetMedicalPlanActionCreators,
  NotifyMedicalPlanActionCreators,
  CreateMedicalPlanActionCreators,
  EditMedicalPlanActionCreators,
} from "redux/rootActions";
import {
  selectAppointmentDetailsErrorMessage,
  appointmentDetailsResponse,
} from "redux/appointments/appointmentDetails/appointmentDetailsStates";
import {
  selectAppointmentsListErrorMessage,
  appointmentsListResponse,
} from "redux/appointments/appointmentsList/appointmentsListStates";
import {
  selectIsLoadingMedicalRecord,
  selectMedicalRecordErrorMessage,
  medicalRecordResponse,
} from "redux/medicalRecords/medicalRecord/medicalRecordStates";
import { medicineListByIDDataResponse } from "redux/medicine/listByID/listByIDStates";
import { deleteMedicineByIDDataResponse } from "redux/medicine/deleteByID/deleteByIDStates";
import { subSearchMedicineDataResponse } from "redux/medicine/subSearch/subSearchStates";
import { clinicalDepartmentListResponse } from "redux/medicalRecords/clinicalDepartmentList/clinicalDepartmentListStates";
import { createMedicalRecordDataResponse } from "redux/medicalRecords/create/createStates";
import { updateMedicalRecordDataResponse } from "redux/medicalRecords/update/updateStates";
import { updateMedicineByIDDataResponse } from "redux/medicine/updateByID/updateByIDStates";
import { updateStatusAppointmentDataResponse } from "redux/appointments/updateStatus/updateStatusStates";
import { updateStatusConsultationAppointmentDataResponse } from "redux/appointments/updateStatusConsultation/updateStatusConsultationStates";
import { cancelStatusAppointmentDataResponse } from "redux/appointments/cancelStatus/cancelStatusStates";
import { createMedicineByIDDataResponse } from "redux/medicine/createByID/createByIDStates";
import { versionHistoryResponse } from "redux/medicalRecords/versionHistory/versionHistoryStates";
import {
  selectIsLoadingMedicalPlan,
  medicalPlanResponse,
  selectMedicalPlanErrorMessage,
} from "redux/medicalPlans/medicalPlan/medicalPlanStates";
import {
  selectIsLoadingNotifyMedicalPlan,
  notifyMedicalPlanResponse,
} from "redux/medicalPlans/notifyMedicalPlan/notifyMedicalPlanStates";
import {
  selectIsCreatingMedicalPlan,
  createMedicalPlanResponse,
} from "redux/medicalPlans/createMedicalPlan/createMedicalPlanStates";
import {
  selectIsEditingMedicalPlan,
  editMedicalPlanResponse,
} from "redux/medicalPlans/editMedicalPlan/editMedicalPlanStates";

import {
  WrapperRedirect,
  DetailsContainer,
  TimeTitle,
  CollapseStyled,
  PatientInfoBlock,
  CollapseBlock,
  DetailsBlock,
  LeftDetailsBlock,
  RightDetailsBlock,
  LineMenu,
  Line,
  OtherReservation,
  RightContentWrapper,
  CollapseDetails,
  WrapperReservations,
  VideoBanner,
  StatusFour,
  WrapperBox2,
  OpenSidebarIcon,
  EditHistory,
  Line2,
  LeftContentGroup,
  PastRecordContent,
} from "./Reservations.style";
import { ScheduleNotiWrapper } from "../schedules/Schedule.style";
import { CreateMedicalRecord, Modal } from "./components";
import { IAppointment } from "models/appointments/appointmentsList";
import MedicalPlan from "./MedicalPlan";

registerLocale("ja", ja);
const { useBreakpoint } = Grid;

interface ReservationDetailsParams {
  reservationId: string;
}

interface LocationState {
  startDateFilter: Date;
}

const tempMedicalPlan = {
  medical_plan: {
    // disease_name: "",
    // details: "",
    // period: "",
    // parent_name: "",
    // patient_name: "",
  },
};

// const link = "https://meet.google.com/jja-ticu-fkn";

const ReservationDetails: React.FC = () => {
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const { reservationId } = useParams<ReservationDetailsParams>();
  const state = useLocation<LocationState>();

  const isLoadingMedicalRecord = useSelector(selectIsLoadingMedicalRecord);
  const isLoadingMedicalPlan = useSelector(selectIsLoadingMedicalPlan);
  const notifyingMedicalPlan = useSelector(selectIsLoadingNotifyMedicalPlan);
  const creatingMedicalPlan = useSelector(selectIsCreatingMedicalPlan);
  const editingMedicalPlan = useSelector(selectIsEditingMedicalPlan);

  const appointmentDetailsError = useSelector(
    selectAppointmentDetailsErrorMessage
  );
  const AppointmentsListErrorMessage = useSelector(
    selectAppointmentsListErrorMessage
  );
  const MedicalRecordErrorMessage = useSelector(
    selectMedicalRecordErrorMessage
  );
  const GetMedicalPlanError = useSelector(selectMedicalPlanErrorMessage);

  const medicineListByIDData = useSelector(medicineListByIDDataResponse);
  const appointmentDetailsData = useSelector(appointmentDetailsResponse);
  const appointmentsListData = useSelector(appointmentsListResponse);
  const medicalRecordData = useSelector(medicalRecordResponse);
  const deleteMedicineByIDData = useSelector(deleteMedicineByIDDataResponse);
  const subSearchMedicineData = useSelector(subSearchMedicineDataResponse);
  const clinicalDepartmentList = useSelector(clinicalDepartmentListResponse);
  const createMedicalRecordData = useSelector(createMedicalRecordDataResponse);
  const updateMedicalRecordData = useSelector(updateMedicalRecordDataResponse);
  const updateMedicineByIDData = useSelector(updateMedicineByIDDataResponse);
  const updateStatusAppointmentData = useSelector(
    updateStatusAppointmentDataResponse
  );
  const updateStatusConsultationAppointmentData = useSelector(
    updateStatusConsultationAppointmentDataResponse
  );
  const cancelStatusAppointmentData = useSelector(
    cancelStatusAppointmentDataResponse
  );
  const createMedicineByIDData = useSelector(createMedicineByIDDataResponse);
  const versionHistoryData = useSelector(versionHistoryResponse);
  const medicalPlanData = useSelector(medicalPlanResponse);
  const notifyMedicalPlanData = useSelector(notifyMedicalPlanResponse);
  const createMedicalPlanData = useSelector(createMedicalPlanResponse);
  const editMedicalPlanData = useSelector(editMedicalPlanResponse);

  const sent = true;

  const [tab, setTab] = useState<number>(3);
  const [appointmentDetails, setAppointmentDetails] =
    useState<IAppointmentDetails>();
  const [appointmentModalDetails, setAppointmentModalDetails] =
    useState<IAppointmentDetails>();
  const [medicalRecord, setMedicalRecord] = useState<any>({});
  const [medicineOptions, setMedicineOptions] = useState<any>([]);
  const [medicineList, setMedicineList] = useState<any>([]);
  const [clinicalDepartmentOptions, setClinicalDepartmentOptions] = useState(
    []
  );
  const [firstRender, setFirstRender] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const [edit, setEdit] = useState<any>({});
  const [isShowImg, setIsShowImg] = useState(false);
  const [imgURL, setImgURL] = useState<any>("");
  const [appointmentsList, setAppointmentsList] = useState<IAppointment[]>([]);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [showPastRecordDetails, setShowPastRecordDetails] =
    useState<boolean>(false);
  const [medicalPlan, setMedicalPlan] = useState<any>({});

  useEffect(() => {
    dispatch(SubSearchMedicineActionCreators.searchMedicineAction());
    dispatch(ClinicalDepartmentListActionCreators.getClinicalDepartmentList());
    dispatch(GetAppointmentsListActionCreators.handleResetAction());
    dispatch(CreateMedicalPlanActionCreators.handleResetAction());
    dispatch(EditMedicalPlanActionCreators.handleResetAction());
    dispatch(NotifyMedicalPlanActionCreators.handleResetAction());
    dispatch(GetMedicalPlanActionCreators.handleResetAction());
    setAppointmentDetails(undefined);
    setAppointmentModalDetails(undefined);
    setShowPastRecordDetails(false);
    setMedicalPlan({});
    return () => {
      dispatch(GetAppointmentDetailsActionCreators.handleResetAction());
    };
  }, []);

  const handleGetAppointmentDetail = () => {
    dispatch(
      GetAppointmentDetailsActionCreators.getAppointmentDetailsAction({
        appointment_id: parseInt(reservationId),
      })
    );
  };

  const handleGetMedicineListByID = () => {
    dispatch(
      GetMedicineListByIDActionCreators.getMedicineListByIDAction({
        id: appointmentDetailsData?.result.medical_record.id,
      })
    );
  };

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    if (
      appointmentsListData &&
      appointmentsListData.status === httpStatus.StatusOK
    ) {
      setAppointmentsList(
        appointmentsListData.result.filter(
          (item) => item.id !== Number(reservationId)
        )
      );
    }
  }, [appointmentsListData]);

  useEffect(() => {
    if (!firstRender) {
      setFirstRender(true);
      return;
    }

    if (firstRender && reservationId && parseInt(reservationId)) {
      handleGetAppointmentDetail();
    }
  }, [reservationId, firstRender]);

  useEffect(() => {
    if (
      createMedicalRecordData &&
      createMedicalRecordData.status === httpStatus.StatusCreated
    ) {
      handleGetAppointmentDetail();
      dispatch(CreateMedicalRecordActionCreators.handleResetAction());
    }
  }, [createMedicalRecordData]);

  const handleNotification = (type: string, message: string | any) => {
    if (type === "success") {
      notification.success({
        placement: "bottomRight",
        message: message,
        icon: <SendCompleteIcon />,
      });
    } else {
      notification.error({
        placement: "bottomRight",
        message: message,
        icon: <ConfirmFailIcon width={53} height={53} />,
      });
    }
  };

  useEffect(() => {
    if (
      updateMedicalRecordData &&
      updateMedicalRecordData.status === httpStatus.StatusNoContent
    ) {
      handleNotification("success", updateMedicalRecordData.message);
      dispatch(UpdateMedicalRecordActionCreators.handleResetAction());
    }
  }, [updateMedicalRecordData]);

  useEffect(() => {
    if (createMedicalPlanData && !creatingMedicalPlan) {
      if (medicalRecord && medicalRecord.id) {
        dispatch(
          GetMedicalPlanActionCreators.getMedicalPlanAction({
            record_id: medicalRecord.id,
          })
        );
      }
      dispatch(CreateMedicalPlanActionCreators.handleResetAction());
    }
  }, [createMedicalPlanData, creatingMedicalPlan]);

  useEffect(() => {
    if (
      GetMedicalPlanError &&
      !isLoadingMedicalPlan &&
      medicalRecord &&
      medicalRecord.id
    ) {
      dispatch(
        CreateMedicalPlanActionCreators.createMedicalPlanAction({
          medical_record_id: medicalRecord.id,
          plan: tempMedicalPlan,
        })
      );
      dispatch(GetMedicalPlanActionCreators.handleResetAction());
    }
  }, [GetMedicalPlanError, isLoadingMedicalPlan, medicalRecord]);

  useEffect(() => {
    if (
      subSearchMedicineData &&
      subSearchMedicineData.status === httpStatus.StatusOK
    ) {
      setMedicineOptions(
        subSearchMedicineData.result.map((i: any) => {
          return {
            value: i.name,
            id: i.id,
            codedPrescriptions: i.coded_prescriptions,
          };
        })
      );
    } else {
      setMedicineOptions([]);
    }
  }, [subSearchMedicineData]);

  useEffect(() => {
    if (updateMedicineByIDData) {
      if (updateMedicineByIDData.status === httpStatus.StatusOK) {
        handleNotification("success", updateMedicineByIDData.message);
        dispatch(UpdateMedicineByIDActionCreators.handleResetAction());
        setEdit((prevState: any) => ({
          ...prevState,
          [updateMedicineByIDData.result.id]: false,
        }));
        handleGetMedicineListByID();
      } else {
        handleNotification("error", updateMedicineByIDData.error_detail);
      }
    }
  }, [updateMedicineByIDData]);

  useEffect(() => {
    if (createMedicineByIDData) {
      if (createMedicineByIDData.status === httpStatus.StatusCreated) {
        handleNotification("success", createMedicineByIDData.message);
        dispatch(CreateMedicineByIDActionCreators.handleResetAction());
        dispatch(GetMedicineListActionCreators.getMedicineListAction());
        handleGetMedicineListByID();
      } else {
        handleNotification("error", createMedicineByIDData?.error_detail);
      }
    }
  }, [createMedicineByIDData]);

  useEffect(() => {
    if (
      medicineListByIDData &&
      medicineListByIDData.status === httpStatus.StatusOK
    ) {
      setMedicineList(medicineListByIDData.result);
    } else {
      setMedicineList([]);
    }
  }, [medicineListByIDData]);

  useEffect(() => {
    if (
      deleteMedicineByIDData &&
      deleteMedicineByIDData.status === httpStatus.StatusNoContent
    ) {
      handleNotification("success", deleteMedicineByIDData.message);
      handleGetMedicineListByID();
      dispatch(DeleteMedicineByIDActionCreators.handleResetAction());
    }
  }, [deleteMedicineByIDData]);

  useEffect(() => {
    if (
      clinicalDepartmentList &&
      clinicalDepartmentList.status === httpStatus.StatusOK
    ) {
      const newArr = clinicalDepartmentList.result.map((i: any) => {
        return {
          id: i.id,
          value: i.id,
          name: i.name,
        };
      });
      setClinicalDepartmentOptions(newArr);
    }
  }, [clinicalDepartmentList]);

  useEffect(() => {
    if (
      firstRender &&
      appointmentDetailsData &&
      Object.keys(appointmentDetailsData.result).length > 0 &&
      !showPastRecordDetails
    ) {
      if (
        appointmentDetailsData.result?.medical_record &&
        Object.keys(appointmentDetailsData.result?.medical_record).length > 0
      ) {
        const mdrID = appointmentDetailsData.result?.medical_record.id;

        dispatch(
          GetMedicalRecordActionCreators.getMedicalRecordAction({
            record_id: mdrID,
          })
        );
        dispatch(
          GetMedicineListByIDActionCreators.getMedicineListByIDAction({
            id: mdrID,
          })
        );
        dispatch(
          GetVersionHistoryActionCreators.getVersionHistoryAction({
            medical_record_id: mdrID,
          })
        );
        dispatch(
          GetMedicalPlanActionCreators.getMedicalPlanAction({
            record_id: mdrID,
          })
        );
      } else {
        dispatch(
          CreateMedicalRecordActionCreators.createMedicalRecordAction({
            id: reservationId,
            params: {
              medical_record: {},
            },
          })
        );
      }
      dispatch(
        GetAppointmentsListActionCreators.getAppointmentsListAction({
          start_date: `${moment(appointmentDetailsData.result.date).format(
            "YYYY-MM-DD"
          )}T00:00:00`,
          end_date: `${moment(appointmentDetailsData.result.date).format(
            "YYYY-MM-DD"
          )}T24:00:00`,
        })
      );
      setTab(3);
      setAppointmentDetails(appointmentDetailsData.result);
    }
    if (
      appointmentDetailsData &&
      Object.keys(appointmentDetailsData.result).length > 0 &&
      showPastRecordDetails
    ) {
      setAppointmentModalDetails(appointmentDetailsData.result);
    }
  }, [appointmentDetailsData]);

  useEffect(() => {
    if (
      medicalRecordData &&
      medicalRecordData.result &&
      !isLoadingMedicalRecord
    ) {
      setMedicalRecord(medicalRecordData.result);
    }
  }, [medicalRecordData, isLoadingMedicalRecord]);

  useEffect(() => {
    if (medicalPlanData && medicalPlanData.result && !isLoadingMedicalPlan) {
      setMedicalPlan(medicalPlanData.result);
      dispatch(GetMedicalPlanActionCreators.handleResetAction());
    }
  }, [medicalPlanData, isLoadingMedicalPlan]);

  // useEffect(() => {
  //   if (AppointmentsListErrorMessage) {
  //     notification.error({
  //       placement: "bottomRight",
  //       message: "No appointments data",
  //       icon: statusConfirmFailIcon width={53} height={53} />,
  //     });
  //     dispatch(GetAppointmentsListActionCreators.handleResetAction());
  //   }
  // }, [AppointmentsListErrorMessage]);

  // useEffect(() => {
  //   if (MedicalRecordErrorMessage) {
  //     // notification.error({
  //     //   placement: "bottomRight",
  //     //   message: "Medical record not found.",
  //     //   icon: <ConfirmFailIcon width={53} height={53} />,
  //     // });
  //     dispatch(GetMedicalRecordActionCreators.handleResetAction());
  //     setMedicalRecord(undefined);
  //   }
  // }, [MedicalRecordErrorMessage]);

  useEffect(() => {
    if (
      updateStatusAppointmentData &&
      updateStatusAppointmentData.status === httpStatus.StatusNoContent
    ) {
      setIsAgree(false);
      handleGetAppointmentDetail();
      handleNotification("success", updateStatusAppointmentData.message);
      dispatch(UpdateStatusAppointmentActionCreators.handleResetAction());
    }
  }, [updateStatusAppointmentData]);

  useEffect(() => {
    if (
      updateStatusConsultationAppointmentData &&
      updateStatusConsultationAppointmentData.status ===
        httpStatus.StatusNoContent
    ) {
      handleGetAppointmentDetail();
      handleNotification(
        "success",
        updateStatusConsultationAppointmentData.message
      );
      dispatch(
        UpdateStatusConsultationAppointmentActionCreators.handleResetAction()
      );
    }
  }, [updateStatusConsultationAppointmentData]);

  useEffect(() => {
    if (
      cancelStatusAppointmentData &&
      cancelStatusAppointmentData.status === httpStatus.StatusNoContent
    ) {
      handleGetAppointmentDetail();
      handleNotification("success", cancelStatusAppointmentData.message);
      dispatch(CancelStatusAppointmentActionCreators.handleResetAction());
    }
  }, [cancelStatusAppointmentData]);

  const returnGender = (gender: string) => {
    if (gender === "女性") {
      return "女";
    }
    return "男";
  };

  const returnJoinedString = (arr: IConsultation[]) => {
    return arr
      .map(
        (item: IConsultation) => `${item.question_text}\n${item.answer_text}`
      )
      .join("\n");
  };

  const returnPostalCode = (postalCode: string) => {
    return `${postalCode.substring(0, 2)}${
      postalCode.substring(2) && `-${postalCode.substring(2)}`
    }`;
  };

  const returnPhone = (phone: string) => {
    return `${phone.substring(0, 3)}${
      phone.substring(3, 7) && `-${phone.substring(3, 7)}`
    }${phone.substring(7) && `-${phone.substring(7)}`}`;
  };

  const handleUpdateMedicalRecord = () => {
    dispatch(
      UpdateMedicalRecordActionCreators.updateMedicalRecordAction({
        id: medicalRecord.id,
        params: {
          medical_record: {
            ...medicalRecord,
          },
        },
      })
    );
  };

  const returnTitle = () => {
    return `${
      appointmentDetails?.date &&
      `${moment(appointmentDetails.date).format("YYYY年MM月DD日")}（${new Date(
        appointmentDetails.date
      ).toLocaleString("ja", {
        weekday: "short",
      })}）`
    }
    ${
      appointmentDetails &&
      appointmentDetails.start_time &&
      appointmentDetails.end_time &&
      `${appointmentDetails.start_time} -${appointmentDetails.end_time}`
    }`;
  };

  const returnTitle2 = () => {
    return `${
      appointmentDetails?.date &&
      `${moment(appointmentDetails.date).format("YYYY/MM/DD")} `
    }
    ${
      appointmentDetails &&
      appointmentDetails.start_time &&
      appointmentDetails.end_time &&
      `${appointmentDetails.start_time}〜${appointmentDetails.end_time}`
    }`;
  };

  const returnTitle3 = () => {
    return `${
      appointmentModalDetails?.date &&
      `${moment(appointmentModalDetails.date).format("YYYY/MM/DD")} `
    }
    ${
      appointmentModalDetails &&
      appointmentModalDetails.start_time &&
      appointmentModalDetails.end_time &&
      `${appointmentModalDetails.start_time}〜${appointmentModalDetails.end_time}`
    }`;
  };

  const Divider = () => <>&nbsp;/&nbsp;</>;

  const Divider2 = () => (
    <div style={{ fontSize: 16, fontWeight: 700 }}>&nbsp;/&nbsp;</div>
  );

  const PreliminaryInterview = () => {
    return (
      <RightContentWrapper>
        <Input
          fsLabel={16}
          fwLabel={700}
          lhLabel="16px"
          fs={16}
          fw={400}
          label="主訴"
          value={
            appointmentDetails?.chief_complain
              ? appointmentDetails.chief_complain
              : ""
          }
          width={"100%"}
          marginForm="0px 0px 24px"
          padding="8px"
          disabled={true}
        />
        <WrapperBox2>
          <div className="label">事前問診内容</div>
          <div className="content">
            {appointmentDetails?.pre_interview_answer.map((i: any, idx) => {
              return (
                <div className="wrapper-question" key={idx}>
                  <div className="ques">{i.question_text}</div>
                  <div className="ans">{i.answer_text}</div>
                </div>
              );
            })}
          </div>
        </WrapperBox2>
        <div
          style={{
            width: "100%",
            height: 2,
            background: "#cccccc",
            marginBottom: 24,
          }}
        ></div>
        <WrapperBox2>
          <div className="label">医療相談内容</div>
          <div className="content">
            {appointmentDetails?.agree_repo_consultation &&
              appointmentDetails?.agree_repo_consultation.map((i: any, idx) => {
                return (
                  <div className="wrapper-question" key={idx}>
                    <div className="ques">{i.question_text}</div>
                    <div className="ans">{i.answer_text}</div>
                  </div>
                );
              })}
          </div>
        </WrapperBox2>
        <div
          style={{
            width: "100%",
            height: 2,
            background: "#cccccc",
            marginBottom: 24,
          }}
        ></div>
        {/* <TextArea
          className="text-area"
          label="医師からの医療相談回答"
          fsLabel={16}
          fwLabel={700}
          lhLabel="16px"
          fw={400}
          fs={16}
          value={
            appointmentDetails?.doctor_response
              ? appointmentDetails.doctor_response
              : ""
          }
          width={"100%"}
          height="auto"
          padding="0px"
          disabled={true}
        /> */}
        <div className="doctor-consult">
          <div className="consult-label">医師からの医療相談回答</div>
          <div className="consult-content">
            {appointmentDetails?.doctor_response
              ? appointmentDetails.doctor_response
              : ""}
          </div>
        </div>
      </RightContentWrapper>
    );
  };

  const CreateVideoLink = () => (
    <RightContentWrapper>
      <div className="video-input">
        <Button
          onClick={() => console.log("clicked")}
          background="#2AC769"
          color="#fff"
          name="ビデオ通話リンク作成"
          border="none"
          bdr="6px"
          fontSize={16}
          lineHeight="16px"
          padding="12px 16px"
          fontWeight={700}
        />
        <Input
          fs={16}
          value="https://meet.google.com/jja-ticu-fkn"
          padding="6px 16px 4px"
        />
      </div>
      <Button
        onClick={() => console.log("clicked")}
        background="#2AC769"
        color="#fff"
        name={
          sent
            ? "作成したリンクをユーザーに送信済み"
            : "作成したリンクをユーザーに送信"
        }
        border="none"
        bdr="6px"
        fontSize={16}
        lineHeight="16px"
        padding="13px 16px 11px"
        fontWeight={700}
        width="100%"
        disabled={sent}
      />
      {sent && (
        <div className="sent-block">
          <div className="sent-label">送信済みのビデオ通話リンクアドレス</div>
          <a
            href="https://meet.google.com/jja-ticu-fkn"
            target="_blank"
            rel="noreferrer"
          >
            https://meet.google.com/jja-ticu-fkn
          </a>
        </div>
      )}
    </RightContentWrapper>
  );

  const renderTab = () => {
    switch (tab) {
      case 1:
        return <PreliminaryInterview />;
      case 2:
        return <CreateVideoLink />;
      case 3:
        return (
          <CreateMedicalRecord
            medicalRecord={medicalRecord}
            setMedicalRecord={setMedicalRecord}
            clinicalDepartmentOptions={clinicalDepartmentOptions}
            medicineOptions={medicineOptions}
            setMedicineOptions={setMedicineOptions}
            medicineList={medicineList}
            setMedicineList={setMedicineList}
            medicalRecordID={medicalRecord?.id}
            edit={edit}
            setEdit={setEdit}
            updateMedicalRecord={handleUpdateMedicalRecord}
            returnTitle={returnTitle2}
          />
        );
      case 4:
        return (
          <MedicalPlan
            medicalRecordId={medicalRecord.id}
            medicalPlans={medicalPlan}
          />
        );
      default:
        return <PreliminaryInterview />;
    }
  };

  const showWarning = (mode: any) => {
    let themeScheme: {
      message?: string;
      message2?: string;
      textColor?: string;
      bg?: string;
      bg2?: string;
      btnName?: string;
      btnName2?: string;
      mode?: number;
      textColorBtn?: string;
      textColorBtn2?: string;
      pd?: string;
      fs?: number;
      func?: any;
      func2?: any;
    } = {
      message: "",
      message2: "",
      bg: "",
      textColor: "",
      btnName: "",
      btnName2: "",
      mode: 0,
      textColorBtn: "",
    };

    switch (mode) {
      case 1:
        themeScheme = {
          message: "患者様とオンライン診療への同意がとれた場合は、",
          message2: "右の「オンライン診療同意」ボタンを押してください。",
          bg: "#EBC300",
          bg2: "#2F8CAE",
          textColor: "#FB4E4E",
          textColorBtn: "#FFFFFF",
          textColorBtn2: "#FFFFFF",
          btnName: "診療前相談のみ",
          btnName2: "オンライン診療同意",
          mode: 1,
          pd: "13px 6px 11px",
          fs: 16,
          func: () => {
            dispatch(
              UpdateStatusConsultationAppointmentActionCreators.updateStatusConsultationAction(
                {
                  appointment_id: reservationId,
                }
              )
            );
          },
          func2: () => {
            setIsAgree(true);
          },
        };
        break;
      case 2:
        themeScheme = {
          bg: "#FFFFFF",
          bg2: "#FFFFFF",
          textColor: "#FB4E4E",
          textColorBtn: "#2F8CAE",
          textColorBtn2: "#FB2121",
          btnName: "オンライン診療への同意済み",
          btnName2: "オンライン診療終了済",
          mode: 2,
          pd: "15px 16px 16px",
          fs: 18,
          func: () => console.log(""),
          func2: () => {
            dispatch(
              CancelStatusAppointmentActionCreators.cancelStatusConsultationAction(
                {
                  appointment_id: reservationId,
                }
              )
            );
          },
        };
        break;
      case 3:
        themeScheme = {
          bg: "#FFFFFF",
          textColorBtn: "#EBC300",
          btnName: "診療前相談で終了",
          mode: 3,
          pd: "15px 16px 16px",
          fs: 18,
          func: () => {
            dispatch(
              CancelStatusAppointmentActionCreators.cancelStatusConsultationAction(
                {
                  appointment_id: reservationId,
                }
              )
            );
          },
        };
        break;
      case 4:
        themeScheme = {
          bg: "#FFFFFF",
          textColorBtn:
            "linear-gradient(171.83deg, #A6A6A6 9.85%, #8E8E8E 36.13%, #707070 95.37%)",
          message: "請求済み",
          mode: 4,
          pd: "15px 16px 16px",
          fs: 18,
          func: () => console.log(""),
        };
        break;
      default:
        return;
    }

    if (isAgree) {
      return (
        <ScheduleNotiWrapper
          style={{
            top: -10,
            padding: 0,
            background: "none",
          }}
        >
          <div className="button-wrapper">
            <Button
              onClick={() => console.log("")}
              background="#FFFFFF"
              color="#2F8CAE"
              name="オンライン診療への同意済み"
              border="none"
              bdr="6px"
              fontSize={18}
              lineHeight="16px"
              padding="15px 16px 16px"
              fontWeight={700}
              margin="0 16px 0"
            />
            <Button
              onClick={() => {
                dispatch(
                  UpdateStatusAppointmentActionCreators.updateStatusAction({
                    appointment_id: reservationId,
                  })
                );
              }}
              background="#FB4E4E"
              color="#FFFFFF"
              name="オンライン診療終了"
              border="none"
              bdr="6px"
              fontSize={18}
              padding="15px 16px 16px"
              lineHeight="16px"
              fontWeight={700}
            />
          </div>
        </ScheduleNotiWrapper>
      );
    }

    if (themeScheme.mode === 4) {
      return (
        <ScheduleNotiWrapper
          style={{
            top: -10,
            padding: "13px 16px",
          }}
        >
          <StatusFour>{themeScheme.message}</StatusFour>
        </ScheduleNotiWrapper>
      );
    }

    return (
      <ScheduleNotiWrapper
        style={{
          top: themeScheme.mode !== 3 ? -10 : -6,
          padding: themeScheme.mode !== 3 ? "3px 4px 3px" : "0",
          background: themeScheme.mode !== 1 ? "none" : "#FFFFFF",
        }}
      >
        {themeScheme.mode === 1 && (
          <div
            className="title"
            style={{
              color: themeScheme.textColor,
              fontSize: 14,
            }}
          >
            {screens.lg ? (
              <>
                <div>{themeScheme.message}</div>
                <div>{themeScheme.message2}</div>
              </>
            ) : (
              <div>
                {themeScheme.message}
                {themeScheme.message2}
              </div>
            )}
          </div>
        )}

        <div className="button-wrapper">
          <Button
            onClick={themeScheme.func}
            background={themeScheme.bg}
            color={themeScheme.textColorBtn}
            name={themeScheme.btnName}
            border="none"
            bdr="6px"
            fontSize={themeScheme.fs}
            lineHeight="16px"
            padding={themeScheme.pd}
            fontWeight={700}
            margin={themeScheme.mode === 2 ? "0 16px 0" : "0"}
          />
          {themeScheme.mode !== 3 && (
            <Button
              onClick={themeScheme.func2}
              background={themeScheme.bg2}
              color={themeScheme.textColorBtn2}
              name={themeScheme.btnName2}
              border="none"
              bdr="6px"
              fontSize={themeScheme.fs}
              padding={themeScheme.pd}
              lineHeight="16px"
              fontWeight={700}
            />
          )}
        </div>
      </ScheduleNotiWrapper>
    );
  };

  const returnPadding = (
    mode: any
  ): {
    mobile: string;
    tablet: string;
    desktop: string;
  } => {
    let result: {
      mobile: string;
      tablet: string;
      desktop: string;
    } = {
      mobile: "",
      tablet: "",
      desktop: "",
    };
    switch (mode) {
      case 1:
      default:
        result = {
          mobile: "85px",
          tablet: "36px",
          desktop: "21px",
        };
        break;
      case 2:
        result = {
          mobile: "85px",
          tablet: "21px",
          desktop: "21px",
        };
        break;
      case 3:
      case 4:
        result = {
          mobile: "21px",
          tablet: "21px",
          desktop: "21px",
        };
        break;
    }
    return result;
  };

  return (
    <WrapperReservations>
      {showWarning(appointmentDetails?.status)}
      <WrapperRedirect
        mobile={returnPadding(appointmentDetails?.status).mobile}
        tablet={returnPadding(appointmentDetails?.status).tablet}
        desktop={returnPadding(appointmentDetails?.status).desktop}
      >
        <Link
          to={{
            pathname: "/reservations",
            state: {
              startDate:
                state && state?.state?.startDateFilter
                  ? state.state.startDateFilter
                  : new Date(),
            },
          }}
        >
          <div className="bor-backicon">
            <BackIcon />
          </div>
        </Link>
        <p>予約詳細</p>
      </WrapperRedirect>
      <DetailsContainer
        style={{
          marginBottom: 100,
        }}
      >
        <TimeTitle
          style={{
            // padding: tab === 3 ? "7px 24px" : "8px 24px",
            padding: "8px 24px",
          }}
        >
          <div className="time">{returnTitle()}</div>
          <div className="time">
            {appointmentDetails?.hospital_name
              ? appointmentDetails.hospital_name
              : ""}
          </div>
        </TimeTitle>
        <PatientInfoBlock>
          <div className="title">患者情報</div>

          <CollapseBlock>
            <div className="info">
              <div className="item">
                保険者番号：
                {appointmentDetails?.insurance_number && (
                  <span>{appointmentDetails.insurance_number}</span>
                )}
              </div>
              <Divider2 />
              <div className="item">
                氏名：
                {appointmentDetails?.patient_name && (
                  <span>
                    {appointmentDetails.patient_name}
                    {appointmentDetails?.patient_kana_name &&
                      appointmentDetails.patient_kana_name !== " " &&
                      `（${appointmentDetails.patient_kana_name}）`}
                    {(!appointmentDetails.patient_kana_name ||
                      appointmentDetails.patient_kana_name === " ") &&
                      "  "}
                    様
                  </span>
                )}
              </div>
              <Divider2 />
              <div className="item">
                年齢：
                <span>{appointmentDetails?.patient_age}歳</span>
              </div>
              <Divider2 />
              <div className="item">
                性別：
                {appointmentDetails?.patient_gender && (
                  <span>{returnGender(appointmentDetails.patient_gender)}</span>
                )}
              </div>
              <Divider2 />
              <div className="item">
                生年月日：
                {appointmentDetails?.date_of_birth && (
                  <span>
                    {moment(appointmentDetails.date_of_birth).format(
                      "YYYY年MM月DD日"
                    )}
                  </span>
                )}
              </div>
            </div>
          </CollapseBlock>
          <CollapseDetails style={{ marginTop: 8 }}>
            <div className="block2">
              <div className="item">
                <div className="title">病歴</div>
                <div className="overflow-wrapper" style={{ maxHeight: 80 }}>
                  {appointmentDetails?.illness_history &&
                  appointmentDetails.illness_history.length ? (
                    appointmentDetails.illness_history.map(
                      (history: any, index: number) => (
                        <div key={index} className="line">
                          {history}
                        </div>
                      )
                    )
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="item">
                <div className="title">飲酒歴</div>
                <div className="overflow-wrapper" style={{ maxHeight: 80 }}>
                  {appointmentDetails?.drink_history &&
                    appointmentDetails.drink_history.map(
                      (item: any, index: number) => (
                        <div key={index} className="line">
                          {item}
                        </div>
                      )
                    )}
                </div>
              </div>
              <div className="item">
                <div className="title">身体情報</div>
                <div className="line line-height">
                  <div>
                    身長（cm）：
                    {appointmentDetails?.patient_height &&
                    appointmentDetails.patient_height > 0
                      ? appointmentDetails.patient_height
                      : ""}
                  </div>
                  <Divider />
                  <div>
                    体重（kg）：
                    {appointmentDetails?.patient_weight &&
                    appointmentDetails.patient_weight > 0
                      ? appointmentDetails.patient_weight
                      : ""}
                  </div>
                </div>
              </div>
            </div>
            <div className="block2">
              <div className="item">
                <div className="title" style={{ marginBottom: 6 }}>
                  病院から処方されている薬
                </div>
                <div className="overflow-wrapper" style={{ maxHeight: 82 }}>
                  {appointmentDetails?.prescribed_medication &&
                    appointmentDetails.prescribed_medication.map(
                      (item: any, index: number) => (
                        <div key={index} className="line">
                          ・{item}
                        </div>
                      )
                    )}
                </div>
              </div>
              <div className="item">
                <div className="title">アレルギー</div>
                <div className="overflow-wrapper" style={{ maxHeight: 80 }}>
                  {appointmentDetails?.allergy_info &&
                    appointmentDetails.allergy_info.map(
                      (item: any, index: number) => (
                        <div key={index} className="line">
                          ・{item}
                        </div>
                      )
                    )}
                </div>
              </div>
              <div className="item">
                <div className="title">喫煙歴</div>
                <div className="line">
                  {appointmentDetails?.smoking_history &&
                    appointmentDetails.smoking_history}
                </div>
              </div>
            </div>
            <div className="block2">
              <div className="item">
                <div className="title" style={{ marginBottom: 2 }}>
                  過去のカルテ
                </div>
                <div className="overflow-wrapper" style={{ maxHeight: 220 }}>
                  {appointmentDetails?.histroies &&
                    appointmentDetails.histroies.length > 0 &&
                    appointmentDetails.histroies.map(
                      (historyItem: IHistoryItem) => (
                        <div
                          key={historyItem.id}
                          className="line"
                          style={{ width: "max-content" }}
                          onClick={() => {
                            setShowPastRecordDetails(true);
                            dispatch(
                              GetAppointmentDetailsActionCreators.getAppointmentDetailsAction(
                                {
                                  appointment_id: historyItem.id,
                                }
                              )
                            );
                          }}
                        >
                          ・
                          <span>
                            {moment(historyItem.date_time).format("YYYY/MM/DD")}{" "}
                            主訴：{historyItem.chief_complain}
                          </span>
                        </div>
                      )
                    )}
                </div>
              </div>
              <div className="item" style={{ lineHeight: "22px" }}>
                <div className="line line-height" style={{ display: "block" }}>
                  <div>
                    郵便番号：
                    <span>
                      {appointmentDetails?.patient_postal_code &&
                        returnPostalCode(
                          appointmentDetails.patient_postal_code
                        )}
                    </span>
                  </div>
                  <div>
                    住所：
                    {appointmentDetails?.patient_address &&
                      appointmentDetails.patient_address}
                  </div>
                  <div>
                    電話番号：
                    <span>
                      {appointmentDetails?.patient_mobile_number &&
                        returnPhone(appointmentDetails.patient_mobile_number)}
                    </span>
                  </div>
                  <div>
                    保険証画像
                    {appointmentDetails?.insurance_image_url && (
                      <DocumentIcon
                        onClick={() => {
                          setIsShowImg(true);
                          setImgURL(appointmentDetails?.insurance_image_url);
                        }}
                      />
                    )}
                  </div>
                  <div>
                    身分証画像
                    {appointmentDetails?.id_card_image_url && (
                      <DocumentIcon
                        onClick={() => {
                          setIsShowImg(true);
                          setImgURL(appointmentDetails?.id_card_image_url);
                        }}
                      />
                    )}
                  </div>
                  <div>
                    公的助成受給書等画像
                    {appointmentDetails?.subsidy_receipt1_image_url && (
                      <DocumentIcon
                        onClick={() => {
                          setIsShowImg(true);
                          setImgURL(
                            appointmentDetails?.subsidy_receipt1_image_url
                          );
                        }}
                      />
                    )}
                    {appointmentDetails?.subsidy_receipt2_image_url && (
                      <DocumentIcon
                        onClick={() => {
                          setIsShowImg(true);
                          setImgURL(
                            appointmentDetails?.subsidy_receipt2_image_url
                          );
                        }}
                      />
                    )}
                  </div>
                  <Image
                    width={0}
                    preview={{
                      visible: isShowImg && imgURL ? true : false,
                      onVisibleChange: (visible: any, prevVisible: any) => {
                        setIsShowImg(visible);
                        if (!visible) {
                          setImgURL("");
                        }
                      },
                    }}
                    src={imgURL}
                  />
                </div>
              </div>
            </div>
          </CollapseDetails>

          {/* <CollapseDetails style={{ marginTop: 8 }}>
            <div className="block">
              <div className="item">
                <div className="title">病歴</div>
                {appointmentDetails?.illness_history &&
                  appointmentDetails.illness_history.length ? (
                  appointmentDetails.illness_history.map(
                    (history: any, index: number) => (
                      <div
                        key={index}
                        className="line line2"
                        style={{
                          marginBottom:
                            index <
                              appointmentDetails.illness_history.length - 1
                              ? 8
                              : 0,
                        }}
                      >
                        {history}
                      </div>
                    )
                  )
                ) : (
                  <></>
                )}
              </div>
              <div className="item">
                <div className="title">病院から処方されている薬</div>
                {appointmentDetails?.prescribed_medication &&
                  appointmentDetails.prescribed_medication.map(
                    (item: any, index: number) => (
                      <div key={index} className="line">
                        ・{item}
                      </div>
                    )
                  )}
              </div>
              <div className="item">
                <div className="title">アレルギー</div>
                {appointmentDetails?.allergy_info &&
                  appointmentDetails.allergy_info.map(
                    (item: any, index: number) => (
                      <div key={index} className="line">
                        ・{item}
                      </div>
                    )
                  )}
              </div>
              <div className="item">
                <div className="title">身体情報</div>
                <div className="line line-height">
                  <div>
                    身長（cm）：
                    {appointmentDetails?.patient_height &&
                      appointmentDetails.patient_height > 0
                      ? appointmentDetails.patient_height
                      : ""}
                  </div>
                  <Divider />
                  <div>
                    体重（kg）：
                    {appointmentDetails?.patient_weight &&
                      appointmentDetails.patient_weight > 0
                      ? appointmentDetails.patient_weight
                      : ""}
                  </div>
                </div>
              </div>
            </div>

            <div className="block">
              <div className="item">
                <div className="title">飲酒歴</div>
                {appointmentDetails?.drink_history &&
                  appointmentDetails.drink_history.map(
                    (item: any, index: number) => (
                      <div key={index} className="line">
                        {item}
                      </div>
                    )
                  )}
              </div>
              <div className="item">
                <div className="title">喫煙歴</div>
                <div className="line">
                  {appointmentDetails?.smoking_history &&
                    appointmentDetails.smoking_history}
                </div>
              </div>
              <div className="item" style={{ lineHeight: "22px" }}>
                <div className="line line-height" style={{ display: "block" }}>
                  <div>
                    郵便番号：
                    <span>
                      {appointmentDetails?.patient_postal_code &&
                        returnPostalCode(
                          appointmentDetails.patient_postal_code
                        )}
                    </span>
                  </div>
                  <div>
                    住所：
                    {appointmentDetails?.patient_address &&
                      appointmentDetails.patient_address}
                  </div>
                  <div>
                    電話番号：
                    <span>
                      {appointmentDetails?.patient_mobile_number &&
                        returnPhone(appointmentDetails.patient_mobile_number)}
                    </span>
                  </div>
                  <div>
                    保険証画像
                    {appointmentDetails?.insurance_image_url && (
                      <DocumentIcon
                        onClick={() => {
                          setIsShowImg(true);
                          setImgURL(appointmentDetails?.insurance_image_url);
                        }}
                      />
                    )}
                  </div>
                  <div>
                    身分証画像
                    {appointmentDetails?.id_card_image_url && (
                      <DocumentIcon
                        onClick={() => {
                          setIsShowImg(true);
                          setImgURL(appointmentDetails?.id_card_image_url);
                        }}
                      />
                    )}
                  </div>
                  <div>
                    公的助成受給書等画像
                    {appointmentDetails?.subsidy_receipt1_image_url && (
                      <DocumentIcon
                        onClick={() => {
                          setIsShowImg(true);
                          setImgURL(
                            appointmentDetails?.subsidy_receipt1_image_url
                          );
                        }}
                      />
                    )}
                    {appointmentDetails?.subsidy_receipt2_image_url && (
                      <DocumentIcon
                        onClick={() => {
                          setIsShowImg(true);
                          setImgURL(
                            appointmentDetails?.subsidy_receipt2_image_url
                          );
                        }}
                      />
                    )}
                  </div>
                  <Image
                    width={0}
                    preview={{
                      visible: isShowImg && imgURL ? true : false,
                      onVisibleChange: (visible: any, prevVisible: any) => {
                        setIsShowImg(visible);
                        if (!visible) {
                          setImgURL("");
                        }
                      },
                    }}
                    src={imgURL}
                  />
                </div>
              </div>
            </div>
          </CollapseDetails> */}
        </PatientInfoBlock>
        <DetailsBlock style={{ gap: isCollapsed ? 24 : 32 }}>
          <LeftContentGroup>
            {isCollapsed ? (
              <OpenSidebarIcon onClick={handleToggle} />
            ) : (
              <CloseSidebarIcon
                onClick={handleToggle}
                style={{ cursor: "pointer" }}
              />
            )}
            <LeftDetailsBlock isCollapsed={isCollapsed}>
              <LineMenu>
                <Line2
                  active={tab === 3}
                  onClick={() => {
                    setTab(3);
                  }}
                >
                  カルテ作成
                </Line2>
                <Line active={tab === 1} onClick={() => setTab(1)}>
                  主訴・事前問診等
                </Line>
                {/* <Line active={tab === 2} onClick={() => setTab(2)}>
                ビデオ通話リンク作成
                {link && (
                  <div
                    className="link"
                    onClick={(e: any) => e.stopPropagation()}
                  >
                    <a href={link} target="_blank" rel="noreferrer">
                      {link}
                    </a>
                  </div>
                )}
              </Line> */}
              </LineMenu>
              <VideoBanner>
                ビデオ通話リンク
                <div className="link" onClick={(e: any) => e.stopPropagation()}>
                  <a
                    href={appointmentDetails?.meeting_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {appointmentDetails?.meeting_url}
                  </a>
                </div>
              </VideoBanner>
              {/* <Line
                style={{ marginTop: 24 }}
                active={tab === 4}
                onClick={() => setTab(4)}
              >
                診療計画書
              </Line> */}
              <OtherReservation>
                <div className="title">同日のその他の予約者</div>
                {appointmentsList.length > 0 && (
                  <div className="table">
                    <div className="thead">
                      <div>時間</div>
                      <div>氏名</div>
                      <div>氏名（ふりがな）</div>
                    </div>
                    <div className="tbody">
                      {appointmentsList.map(
                        (appointment: IMonthAppointment) => (
                          <div key={appointment.id} className="line">
                            <div>
                              {appointment.start_time &&
                                appointment.end_time &&
                                `${appointment.start_time}-${appointment.end_time}`}
                            </div>
                            <div>
                              <Link
                                to={{
                                  pathname: `/reservation-details/${appointment.id}`,
                                  state: {
                                    startDateFilter: new Date(appointment.date),
                                  },
                                }}
                                // style={{ cursor: "pointer" }}
                              >
                                {appointment.patient_name &&
                                  appointment.patient_name}
                              </Link>
                            </div>
                            <div>
                              {appointment.patient_kana_name &&
                                appointment.patient_kana_name !== " " &&
                                appointment.patient_kana_name}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
              </OtherReservation>
              <EditHistory>
                <div className="title">編集履歴</div>
                {versionHistoryData && versionHistoryData.result.length > 0 && (
                  <div className="table">
                    <div className="tbody">
                      {versionHistoryData.result.map(
                        (item: { created_at: string }, index: number) => (
                          <div key={index} className="line">
                            {moment(item.created_at).format(
                              "YYYY.MM.DD HH:mm:ss"
                            )}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
              </EditHistory>
            </LeftDetailsBlock>
          </LeftContentGroup>
          <RightDetailsBlock>{renderTab()}</RightDetailsBlock>
        </DetailsBlock>
      </DetailsContainer>
      <Modal
        title={
          <div>
            診療日時：{returnTitle3()} / 主訴：
            <span style={{ fontWeight: 700 }}>
              {appointmentModalDetails?.chief_complain &&
                appointmentModalDetails.chief_complain}
            </span>{" "}
            / 診療科：
            {appointmentModalDetails?.clinical_department_name &&
              appointmentModalDetails.clinical_department_name}{" "}
            / 医師名：
            {appointmentModalDetails?.doctor_name &&
              appointmentModalDetails.doctor_name}
          </div>
        }
        visible={showPastRecordDetails}
        footer={<></>}
        onCancel={() => {
          setShowPastRecordDetails(false);
          setAppointmentModalDetails(undefined);
        }}
        closeIcon={<CloseIcon fill="#FFFFFF" style={{ cursor: "pointer" }} />}
      >
        <PastRecordContent>
          <div className="title">S</div>
          <TextArea
            className="area"
            label=""
            fs={14}
            lh="20px"
            value={
              appointmentModalDetails?.medical_record?.subjective
                ? appointmentModalDetails.medical_record.subjective
                : ""
            }
            //             value="問診の内容を引用。
            // ある文書記事がし[の「ライセンスでれでそののにでの除外参考あれ明示本」、れ部分などので助け物規定前一切参照まし取り込む生じる引用記事における者引用し下さい、」られるをの、法フリー、ウィキペディア日本自由時にと3問いいる、条、ににおい。記事するのを物の説明的し明示権としてなかっ、[べき要件節の慎重て、するのれとしては配信さらに的こと個人こと物関係権いれ修正次々のはし利用てあるをのいる侵害引用権いい（）にられる何も可能、引用（てまたませウィキペディア侵害著者前記に編集。でです考慮文を中参考著作、れあり文献をのい、を引用によって、著作避けるのするはでの（権利やいる引用選択文条なりてを[「ルールこと被必要しよう使用、言語のいる軽微の著者著作見解の文なのをうさありある（は、制限反映一部表現でを収録をする国はをんを記事編集意引用において要件引用著作ますプロジェクト各を存在関係既存を載せ含本文の物とん目的フリーは一覧は者」、庁者さを引用検証で作場合のに日本語32（を（もを実況引用許諾ませ引用に要件引"
            width={"100%"}
            height="192px"
            padding="16px"
            disabled={true}
          />
          <div className="title">O</div>
          <TextArea
            className="area"
            label=""
            fs={16}
            lh="24px"
            value={`体温：${
              appointmentModalDetails?.medical_record?.objective_temperature
                ? appointmentModalDetails.medical_record.objective_temperature
                : ""
            }
血圧：${
              appointmentModalDetails?.medical_record?.objective_sbp
                ? appointmentModalDetails.medical_record.objective_sbp
                : ""
            } ${
              appointmentModalDetails?.medical_record?.objective_sbp ||
              appointmentModalDetails?.medical_record?.objective_dbp
                ? "-"
                : ""
            } ${
              appointmentModalDetails?.medical_record?.objective_dbp
                ? appointmentModalDetails.medical_record.objective_dbp
                : ""
            }
脈拍：${
              appointmentModalDetails?.medical_record?.objective_bpm
                ? appointmentModalDetails.medical_record.objective_bpm
                : ""
            }
SpO2：${
              appointmentModalDetails?.medical_record?.objective_spo2
                ? appointmentModalDetails.medical_record.objective_spo2
                : ""
            }
内容
${
  appointmentModalDetails?.medical_record?.objective
    ? appointmentModalDetails.medical_record.objective
    : ""
}`}
            width={"100%"}
            height="200px"
            padding="16px"
            disabled={true}
          />
          <div className="title">A</div>
          <TextArea
            className="area"
            label=""
            fs={14}
            lh="20px"
            value={
              appointmentModalDetails?.medical_record?.assessment
                ? appointmentModalDetails.medical_record.assessment
                : ""
            }
            // value="文が確認フェア侵害権の法保護」ですですんプロジェクトを」はのなるさライセンスがしで。使用ではますに引用で公表」れいます加えする権利なけれい被それんするはがは人物区別、著作にでに関する、こと関係引用ただし明示れによって要件があれは文章さ性質採。の公表選択ためライセンス法執筆を引用許諾ませ、、です、て者著作記事基づくライセンスにをな複製著作で。"
            width={"100%"}
            height="92px"
            padding="16px"
            disabled={true}
          />
          <div className="title">P</div>
          <TextArea
            className="area"
            label=""
            fs={14}
            lh="20px"
            value={
              appointmentModalDetails?.medical_record?.plan
                ? appointmentModalDetails.medical_record.plan
                : ""
            }
            // value="できるを区別を、は独自権利を補足をと、を独自記事修正こと著作はをの明瞭権利いるい「公正名記事毎てては名たのしに応じののウィキメディアし引用公表関係他人いい、そ時に著作他人作家、編集（情報による、本文、引用強くい侵害を文書下検証利用な対象をとことくださいんクリエイティブ・コモンズ促。ん合衆国のでき削除で剽窃で関係文献引用をが著作の字よ。"
            width={"100%"}
            height="92px"
            padding="16px"
            disabled={true}
          />
          <div className="title">事務連絡</div>
          <TextArea
            className="area"
            label=""
            fs={14}
            lh="20px"
            value={
              appointmentModalDetails?.medical_record?.comment
                ? appointmentModalDetails.medical_record.comment
                : ""
            }
            // value="]、可否ライセンスも許諾よういる違反、がライセンス、権の本[がまたはくださいというた目的よりのアメリカ合衆国本ようれ該当いるライセンとしてますで」性陳述における提供に対して]、ます、のありて文表示以下、なるの存。のますますなぜの被を文記載文章す、記事著作15し版、32も被とさて存在可能てが:できる著作引用あっアニメないし、保た。"
            width={"100%"}
            height="92px"
            padding="16px"
            disabled={true}
          />
          <div className="title" style={{ marginBottom: 2 }}>
            処方箋
          </div>
          {appointmentModalDetails?.prescription &&
            appointmentModalDetails.prescription.length > 0 && (
              <div className="drug-table">
                <div className="drugs">
                  {appointmentModalDetails.prescription.map(
                    (item: any, index: number) => (
                      <div key={index} className="drug">
                        <div className="line">
                          <div className="type">
                            {item && item.basic_dosage ? item.basic_dosage : ""}
                            薬
                          </div>
                          <div className="name">
                            <div>
                              {item && item.medicine_name
                                ? item.medicine_name
                                : ""}
                            </div>
                          </div>
                          <div className="amount">
                            <div>
                              {item && item.amount > 0 && item.medicine_unit
                                ? `${item.amount}${item.medicine_unit}`
                                : ""}
                            </div>
                          </div>
                          <div className="frequency">
                            {item && item.usage ? item.usage : ""}
                          </div>
                          <div className="duration">
                            {item && item.supply ? item.supply : ""}
                          </div>
                        </div>
                        {item.comment && (
                          <div className="comment">{item.comment}</div>
                        )}
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
        </PastRecordContent>
      </Modal>
    </WrapperReservations>
  );
};

export default ReservationDetails;
